<template>
  <div class="comment">
    <div class="commentitem">
      <div class="itemtop">
        <div class="image">
          <img
            src=""
            alt=""
          >
        </div>
        <div class="topInfo">
          <div style="color:black">王强</div>
          <div>内科</div>
          <div>天全县中医医院</div>
        </div>
        <div class="topButton">
          <van-button @click="$router.push('/commentpage')">点评</van-button>
        </div>
      </div>
      <div class="itembottom">
        <div class="bottomitem">
          <span>姓名</span>
          <span>潘印丝</span>
        </div>
        <div class="bottomitem">
          <span>时间</span>
          <span>2018-09-21(周五)16:30</span>
        </div>
        <div class="bottomitem">
          <span>费用</span>
          <span>￥1.00</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  }
}
</script>

<style lang='less' scoped>
.comment {
  background: #f5f5f5;
  height: 100vh;
  .commentitem {
    border-top: 1rem solid #f5f5f5;
    background: rgb(255, 255, 255);
    padding-bottom: 0.5rem;
    .itemtop {
      display: flex;
      .image {
        width: 5rem;
        height: 5rem;
      }
      .topInfo {
        display: flex;
        flex-direction: column;
        flex: 2;
        justify-content: center;
      }
      .topButton {
        margin: auto 0.3rem;
      }
      .topButton .van-button {
        border: 1px solid #afbcd4;
      }
    }
    .itembottom {
      background: #f3f6ff;
      margin: 0.625rem 0.625rem;
      border-radius: 0.3rem;
      height: 5.12rem;
      padding-top: 0.62rem;
      line-height: 1.56rem;
      .bottomitem span {
        margin-left: 0.625rem;
      }
    }
  }
}
</style>